export function toHtml(text: string) {
  text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  text = text.replace('**', '<strong>');
  text = text.replace('**', '</strong>');
  return text;
}

export function toCleanString(text: string) {
  text = text.replace('**', '');
  text = text.replace('**', '');
  return text;
}
