import React from 'react';
import { Meta } from 'components/Meta';
import Layout from 'components/Layout';
import { graphql, PageProps } from 'gatsby';
import { AudioCards } from 'components/AudioCards';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'resources/styles/pages/default.scss';
import { Back } from 'components/Back';

export const query = graphql`
  query {
    underline3: file(relativePath: { eq: "underline-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    audioCards: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "markdown/audio/.*.md$/" }
        frontmatter: { is_displayed: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        id
        frontmatter {
          audio_file {
            publicURL
          }
          cover {
            copyright
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            subtitle
          }
          slug
          tags {
            text
          }
          title
        }
      }
    }
  }
`;

type DataProps = {
  audioCards: GatsbyTypes.MarkdownRemarkConnection;
  profile: GatsbyTypes.File;
  underline3: GatsbyTypes.File;
};

const Page: React.FC<PageProps<DataProps>> = (props) => {
  const { data } = props;

  /* ToDo: Add filter const getTags = () => {
    let tags = new Array<{ isSelected: boolean; text: string }>();
    data.audioCards.nodes.forEach((item) => {
      if (item.frontmatter?.tags) {
        item.frontmatter.tags.forEach((tag) => {
          if (tag?.text && !tags.some((item2) => item2.text == tag.text)) {
            tags.push({ isSelected: false, text: tag.text });
          }
        });
      }
    });

    return tags.sort((a, b) => a.text.localeCompare(b.text));
  };*/

  return (
    <Layout>
      <main className="default">
        <section className="my-0">
          <div className="py-[20%] md:flex md:flex-col md:justify-center md:py-16 md:min-h-[18rem] md:h-[66vh] md:max-h-[34rem]">
            <h1 className="mt-0 max-w-[15ch]">
              Alle{' '}
              <span className="relative">
                Produktionen
                <GatsbyImage
                  className="absolute left-0 w-full top-1/3 -z-10 dark:opacity-40"
                  image={data.underline3.childImageSharp!.gatsbyImageData}
                  alt="Unterstrichen"
                />
              </span>
            </h1>
            <p>
              <strong>stegermeister®</strong> entwickelt Audioproduktionen mit
              Geschichte – Feature, Hörspiele und Podcasts. Wir konzipieren,
              tüfteln, produzieren: Von der Idee bis ins Ohr.
            </p>
          </div>
          {/* ToDo: Add filter
          <ul className="flex flex-wrap mb-6 items-center ">
            {[{ isSelected: true, text: 'Alle' }]
              .concat(getTags())
              .map((item) => (
                <li
                  className={`button min-w-20 text-xs sm:text-sm py-1 xs:py-2 px-1 xs:px-3 mr-2 mb-2 border-0 justify-center flex ${
                    item.isSelected ? 'isSelected' : ''
                  }`}
                >
                  {item.text}
                </li>
              ))}
          </ul>
          */}

          <AudioCards items={data.audioCards.nodes} />

          <Back />
        </section>
      </main>
    </Layout>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Page;
