import React from 'react';
import { AudioCard } from 'components/AudioCard';

interface Props {
  type?: 'mixed' | 'small';
  items: ReadonlyArray<GatsbyTypes.MarkdownRemark>;
}

export const AudioCards: React.FC<Props> = (props) => {
  const { items, type = 'mixed' } = props;

  const getAudioCardProps = (index: number, length: number) => {
    if (type === 'mixed' && index < length / 4 + 1 && length > index * 3) {
      return {
        type: 'big' as 'big' | 'small',
        className: `md:row-start-${index * 3 + 1} lg:row-start-${
          index * 2 + 1
        } lg:col-start-${(index % 2) + 1}`,
      };
    }

    return { type: 'small' as 'big' | 'small', className: '' };
  };

  return (
    <div className="grid gap-3 -mx-3 auto-rows-fr sm:gap-4 md:grid-cols-2 md:gap-6 lg:gap-8 lg:grid-cols-3 lg:-mx-4 xl:-mx-6">
      {items.map((item, index) => {
        return (
          <AudioCard
            key={item.id}
            {...getAudioCardProps(index, items.length)}
            item={item}
          />
        );
      })}
    </div>
  );
};
