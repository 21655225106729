import React, { useCallback, useContext, useEffect, useState } from 'react';
import Headphones from 'resources/svg/headphones.svg';
import Pause from 'resources/svg/pause.svg';
import { AudioContext } from 'context/AudioContext';

interface Props {
  audio_file: string;
  type?: 'yellow' | 'dark' | 'small';
  className?: string;
}

export const Player: React.FC<Props> = (props) => {
  const { audio_file, type = 'yellow', className } = props;
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [duration, setDuration] = useState('00:00');
  const [currentTime, setCurrentTime] = useState('00:00');
  const { currentFile, setCurrentFile } = useContext(AudioContext);

  useEffect(() => {
    window.addEventListener('click', () => {
      setCurrentFile(null);
    });

    return () => {
      window.removeEventListener('click', () => {});
    };
  }, []);

  const playAudio = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setPlaying(!playing);

    setCurrentFile(!playing ? audio_file : null);
  };

  const getHumanReadableTime = useCallback((time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = `0${Math.floor(time % 60)}`.slice(-2);

    return `${minutes}:${seconds}`;
  }, []);

  const updatePlaybackTime = useCallback(() => {
    if (!audio) {
      return;
    }

    setCurrentTime(getHumanReadableTime(audio.currentTime));
  }, [audio, setCurrentTime]);

  useEffect(() => {
    if (!audio) {
      return;
    }

    if (playing) {
      audio.play();

      return;
    }

    audio.pause();
  }, [playing]);

  useEffect(() => {
    if (!audio || !canPlay) {
      return;
    }

    setDuration(getHumanReadableTime(audio.duration));
  }, [canPlay]);

  useEffect(() => {
    setPlaying(currentFile == audio_file);
  }, [currentFile]);

  useEffect(() => {
    if (!audio) {
      return;
    }

    audio.load();

    audio.addEventListener('ended', () => setPlaying(false));
    audio.addEventListener('canplay', () => setCanPlay(true));
    audio.addEventListener('timeupdate', updatePlaybackTime);

    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
      audio.removeEventListener('canplay', () => setCanPlay(true));
      audio.removeEventListener('timeupdate', updatePlaybackTime);
    };
  }, [audio]);

  useEffect(() => {
    const newAudio = new Audio(audio_file);

    setAudio(newAudio);

    return () => {
      newAudio.pause();
    };
  }, []);

  return canPlay ? (
    <button
      type="button"
      onClick={playAudio}
      className={`${
        type != 'dark'
          ? 'text-yellow border-yellow hover:text-yellow hover:border-yellow hover:bg-yellow hover:bg-opacity-5 hover:border-opacity-90'
          : ''
      } ${className ?? ''} pl-1 pr-8 rounded-full py-1.5 xs:py-2 self-start`}
    >
      <div className="flex items-center">
        <div className="flex-none mx-4 leading-normal">
          {playing ? <Pause /> : <Headphones />}
        </div>
        <div className="flex flex-col items-start">
          {type != 'small' && <div className="leading-tight">Hörprobe</div>}
          <div
            className={`${
              type == 'yellow' ? 'text-white' : ''
            }  leading-none text-xs font-normal`}
          >
            {playing ? (
              <div className="flex tabular-nums">
                <span>{currentTime}</span>
                <span className="mx-0.5">{'/'}</span>
                <span>{duration}</span>
              </div>
            ) : (
              `${duration} Min.`
            )}
          </div>
        </div>
      </div>
    </button>
  ) : null;
};
