import React, { useCallback, useRef } from 'react';
import { Link } from 'gatsby';
import { toHtml } from 'helper/markdown';
import More from 'resources/svg/more.svg';
import { Player } from 'components/Player';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  item: GatsbyTypes.MarkdownRemark;
  type: 'big' | 'small';
  className: string;
  clickable?: boolean;
}

export const AudioCard: React.FC<Props> = (props) => {
  const { type, className, clickable } = props;
  const { frontmatter } = props.item;
  const linkRef = useRef<HTMLAnchorElement>(null);

  const triggerLink = useCallback(() => {
    if (clickable && linkRef.current) {
      linkRef.current.click();
    }
  }, []);

  return (
    <article
      className={`${className} ${
        type === 'big' ? 'md:col-span-2 md:row-span-2' : 'col-span-1'
      } flex grow`}
    >
      <div
        onClick={triggerLink}
        className={`relative transition duration-500 ease-out z-0 flex overflow-hidden text-white grow shadow-card rounded-4xl bg-dark aspect-portrait xxs:aspect-square ${
          clickable
            ? 'cursor-pointer hover:ring-8 hover:ring-dark hover:ring-opacity-10 hover:shadow-card-lg'
            : ''
        }`}
      >
        {frontmatter?.cover?.image && (
          <GatsbyImage
            imgClassName="rounded-4xl"
            className="absolute object-cover w-full h-full -z-10 rounded-4xl"
            image={frontmatter?.cover?.image?.childImageSharp?.gatsbyImageData!}
            alt={frontmatter?.title!}
          />
        )}
        <div className="flex grow bg-radial-gradient-grey rounded-4xl">
          <div className="flex flex-col p-6 grow bg-linear-gradient-grey rounded-4xl xs:p-6 md:p-8">
            {frontmatter?.tags && (
              <ul className="flex text-white text-opacity-75 text-xxs md:text-xs lg:text-sm">
                {frontmatter.tags.map((tag, index) => (
                  <li
                    key={index}
                    className="flex font-semibold after:content-['·'] after:mx-1 last:after:content-[''] last:after:mx-0"
                  >
                    {tag?.text}
                  </li>
                ))}
              </ul>
            )}
            <div className="flex flex-col items-start justify-center flex-1">
              <h2
                className={`text-4xl mt-8 max-w-[16ch] mb-0 xs:text-5xl ${
                  type === 'big'
                    ? 'sm:text-6xl lg:text-8xl lg:mt-16'
                    : 'md:text-2xl lg:text-4xl'
                }`}
                dangerouslySetInnerHTML={{
                  __html: toHtml(frontmatter?.title!),
                }}
              />
              {frontmatter?.cover?.subtitle && (
                <p
                  className={`sr-only ${
                    type === 'big'
                      ? 'text-base max-w-prose sm:not-sr-only sm:mt-6 sm:mb-4 lg:text-lg'
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: toHtml(frontmatter.cover.subtitle),
                  }}
                />
              )}
              <div className="mt-2">
                <Link
                  className="flex items-center self-end p-0 border-none text-yellow hover:text-yellow hover:text-opacity-80"
                  to={`/produktionen/${frontmatter?.slug}`}
                  innerRef={linkRef}
                >
                  <span>Mehr Infos</span>
                  <span className="ml-1 leading-normal">
                    <More />
                  </span>
                </Link>
              </div>
            </div>
            <div className="flex mt-2 -mx-1 -mb-1 md:-mx-2 md:-mb-2">
              {frontmatter?.audio_file?.publicURL && (
                <Player audio_file={frontmatter?.audio_file?.publicURL} />
              )}
              {frontmatter?.cover?.copyright && (
                <cite className="pl-2 text-[0.5625rem] text-right flex-1 -mr-0.5 -mb-1.5 self-end sm:text-[0.625rem] md:text-xxs">
                  © Bild: {frontmatter?.cover?.copyright}
                </cite>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

AudioCard.defaultProps = {
  clickable: true,
};
