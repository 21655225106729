import { Link } from 'gatsby';
import React from 'react';

import ArrowLeft from 'resources/svg/arrow-left.svg';

export const Back: React.FC = () => {
  return (
    <Link className="inline-flex items-center py-8 font-extrabold" to="/">
      <div className="mr-1 leading-normal">
        <ArrowLeft />
      </div>
      <span className="mt-0.5">Zur Startseite</span>
    </Link>
  );
};
